.page {
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
    min-height: 100vh;
    max-width: var(--max-page-width);
}


.main {
    width: 100vw;
    margin-bottom: 115px;
    z-index: 1;
    padding-left: var(--spacing-m);
    padding-top: var(--spacing-m);
}

// Constrain the down arrow so the SVG renders properly
.arrowBackIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: var(--primary);
}

.row {
    display: flex;
    flex-direction: row;

    font-size: var(--font-size);
    margin-bottom: var(--spacing-m);
    font-weight: var(--font-weight);
}

.cell {
    align-items: center;
}

.returnMessage {
    // Font sizes don't have a 24px item
    font-size: 24px;
    color: var(--primary);
}

.link:link { 
    text-decoration: none; 
} 
.link:visited { 
    text-decoration: none; 
} 
.link:hover { 
    text-decoration: none; 
} 
.link:active { 
    text-decoration: none; 
}