/* summary panel container formatting */
.details {
    padding: 16px 0;
    border-bottom: 1px solid var(--neutral-x-light);
}

/* Only include the padding and border between detail panels */
.details:first-of-type {
    padding-top: 0;
}
.details:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
}



/* Formatting of an individual element */
.groupElement {
    /* Layout */
    display: flex;
    padding: 16px 10px 16px 0;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;

    /* Style */

    background: var(--white);
}

.column {
    align-self: center;    
}

.groupTitle {
    color: var(--neutral-dark);

    font-size: var(--font-size);
    font-style: normal;
    font-weight: var(--font-weight-strong);
    line-height: var(--line-height);
}

.groupSubtitle {
    color: var(--neutral);

    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}

.groupExpand {
}

.action {
    display: flex;
    align-items: center;
    height: 100%;
}

/* Remove the default triangle */
summary {
    display: block;
}

.switchIcon {
}
