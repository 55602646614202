/* Formatting of an individual selector component */
.contentPane {
    /* Layout */
    display: flex;
    padding: 16px 24px;
    margin-bottom: 12px;
    flex-direction: row;
    align-items: center;
    align-content: center;
    align-self: stretch;
    justify-content: space-between;

    /* Style */
    border-radius: var(--border-radius-x-rounded);
    border: 1px solid var(--white);
    background: var(--neutral-xx-light);
}

/* Strip the margin from the top and bottom elements as needed */
.contentPane:last-of-type {
    margin-bottom: 0;
}

.contentPane:first-of-type {
    margin-top: 0;
}
