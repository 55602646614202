.card {
    box-sizing: border-box;
    box-shadow: var(--card-drop-shadow);

    // Oddly, need to set the background color in order for it not to be transparent.
    background: var(--background-color);

    border-radius: var(--border-radius-x-rounded);
    border: 1px solid var(--border-color-light);

    margin: 24px auto;
    padding: 24px; /* TODO: Set as constant? */

    /* TODO: Determine if this should be max-width * golden ratio? */
    max-width: 852px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

/* Formatting of the card's title / header text */
.title {
    align-self: stretch;
    margin: 0 0 32px 0;

    color: var(--text-color);
    /* TODO: Extract `Roboto Slab` to a variable in global */
    font-family: Roboto Slab;
}

/* Formatting of an individual selector component */
.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
}
