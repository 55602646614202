// TEXT BUTTON
.textButtonBorder,
.textButton {
    color: var(--primary-dark);
    background-color: var(--white);
    font-size: var(--font-size);
    line-height: 1.5rem;
    margin: 0;
    font-weight: 400;

    padding: 0.625em 1.125em;
    cursor: pointer;
}
.textButtonBorder {
    border: 1px solid var(--primary);
    border-radius: 8px;
}
.textButton {
    border: none;
    color: var(--primary);
}

//TextButtonPrimary
.textButtonPrimary {
    background-color: var(--primary);
    color: var(--white);
    font-size: var(--font-size);
    line-height: 1.5rem;
    margin: 0;
    font-weight: 400;
    padding: 0.625em 1.125em;
    cursor: pointer;
    border-radius: 8px;
    border: none;
}

// ICON BUTTON
.iconButton {
    color: var(--neutral-dark);
    background-color: var(--white);
    height: 52px;
    width: 44px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

// Radio Button
.radioButton {
    display: inline-grid;
    place-content: center;
    width: 24px;
    height: 24px;
    border: 0.15em solid var(--neutral-dark);
    border-radius: 50%;
    appearance: none;
    cursor: pointer;
    &:checked {
        border-color: var(--primary-dark);
        &::before {
            transform: scale(1);
        }
    }
}
.radioButtonLabel {
    cursor: pointer;
}
.radioButton::before {
    display: inline-block;
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary-dark);
}

// TEXT BANNER BUTTON
.textBannerLinkButton {
    text-decoration: none;
    background-color: var(--neutral-xx-light);
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0;
    cursor: pointer;
}

// TEXT BANNER BUTTON
.textBannerBackLinkButton {
    text-decoration: none;
    background-color: var(--background);
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0;
    cursor: pointer;
}

.textBannerButtonText {
    color: var(--primary-dark);
    display: inline;
    font-size: var(--font-size);
    line-height: 1.5rem;
    margin: 0;
    font-weight: 400;
}
.nextIcon {
    fill: var(--primary-dark);
    width: 24px;
    height: 24px;
}
