.card {
    box-sizing: border-box;
    box-shadow: var(--card-drop-shadow);

    border-radius: var(--border-radius-xx-rounded);
    border: 1px solid var(--neutral-light);

    /* Card Size */
    max-width: 275px;
    max-height: 310px;
    width: 275px;
    height: 310px;

    /* Center the card */
    margin: auto;
}

.contents {
    /* Layout */
    display: flex;
    padding: var(--spacing) var(--spacing-m);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    text-align: center;
}

.message {
    font-weight: var(--font-weight-bold);
}
