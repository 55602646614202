// The overall profile container.
.profileBlock {
    display: flex;
    align-items: center;
    padding: 0 2.625rem;
    background-color: var(--primary-color);
}


.imageBlockItem {
    width: 36px;
    height: 36px;
    margin-right: 1em;
}

// Used for styling the user account icon
.accountIcon {
    width: 36x;
    height: 36px;
    vertical-align: middle;
}

// User information and drop down menu block
.userBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.username {
    font-size: var(--font-size-m);
}

// Styling the application name.
.applicationName {
    font-size: var(--font-size-xs);
    font-style: italic;
}
