/* This will put the card in roughly the center the card -- sorta */
.main {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.page {
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
    min-height: 100vh;
    max-width: var(--max-page-width);
}
