

.title {
    color: var(--neutral-dark);
    font-size: var(--font-size-sm);
  }

  .inputText{
    border-radius: 8px;
    border: 1px solid var(--neutral);
    font-size: var(--font-size);
    padding: 10px;
    margin-bottom: 20px;
    width: 90%;
    background-color: var(--neutral-light);
  }

  .button{
    background-color: var(--primary) ;
    color: var(--white) ;
    margin-left: 10px;
    border-radius: 8px;
    height: 40px;   
    width: 50px;
    vertical-align: sub;
    &:hover {
        color: var(--dark);
      }     
  }

  .toolTip{  
    background-color: #262626E5 !important;
    height: 28px;
    width: 55px !important;
    padding: 2px 8px !important;
    font-size: var(--font-size-xs) !important;
  }