.fullwidth {
    background: var(--neutral-xx-light);
    width: 100vw;
}

.container {
    max-width: var(--max-page-width);
    margin: auto;
    padding: 30px 35px;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-s);
    flex-shrink: 0;
}

.title {
    color: var(--dark);
    font-family: Roboto Slab;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}

.subtitle {
    color: var(--neutral);
    font-family: Roboto;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}
