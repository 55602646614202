.modal {
    position: absolute;      
    left: 25%;   
    top: 25%;
    display: inline-block;
    background-color: white;
    min-width: 44em;  
    opacity: 1;
    box-shadow: 0 30px 30px rgba(0,0,0,.2);
    border-radius: 8px;   
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(38, 38, 38, 0.5);
    opacity: 1;   
    z-index: 1000;
  }

  .titleContainer{
    padding: 30px 30px 20px 30px;
  }

  .title{
    color: var(--primary-dark);
    font-size: 25px; 
  }

  .content{ 
    padding: 20px 30px 30px 30px;
  }
  .closeIcon{
    float:right;
    height: 20px;
    color: var(--neutral-dark);
  }

  .banner{  
    background-color: var(--accent--yellow-light);
    height: 70px;
    padding: 10px 20px 10px 20px;
  }

  .info{
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    color: --neutral-dark; 
    margin-left: 20px;
  }

  .infoOther{
    margin-left: 40px;
  }

.infoBold{
    font-weight: var(--font-weight-bold) !important;   
  }

  .warning{
    width: 20px;
    height: 20px; 
    position: relative; 
    top: 4px;
  }
