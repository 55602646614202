.hwheader {
    background-color: var(--primary-color-dark);
    max-height: 72px;
    color: var(--header-text-color);
    // This is to give gutters to the components of the header
    padding: 0 0 0 24px;
}

.headerBackground {
    background-color: var(--primary-color-dark);
    width: 100vw;
}

.flexify {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.rightSidebar {
    // Need to turn these into variables so they can be ref'ed for the other
    // blocks in the header.
    height: 72px;
    min-width: 196px;
    width: auto;
    background-color: var(--primary-color);
    
    display: flex;
    align-items: center;
}