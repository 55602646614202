.main {
    width: 100vw;
    margin-bottom: 115px;
    z-index: 1;
}

.info{
  font-weight: var(--font-weight);
  font-size: var(--font-size-s);
  min-width: 60%;
  margin-left: 20px;
}

.infoBold{
  font-weight: var(--font-weight-bold) !important;      
}

.infoOther{  
  margin-left: 40px;
}

.border{
  border-radius: 8px;
  border: 1px solid var(--primary);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: stretch; 
}

.apiButton {
  display: flex;
  align-items: center;  
  height: 100%;
}

.warning{
  width: 20px;
  height: 20px; 
  position: relative; 
  top: 6px;
}

  

