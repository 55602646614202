hr {
    // Remove the extra whitespace before/after the line
    margin-block-start: 0em;
    margin-block-end: 0em;
    
    // Just setting the border-color doesn't work...
    border-top: 1px solid var(--neutral-x-light);
}

.userMenuParent {
    position: relative;
}

.menuModal {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    min-width: 300px;
    position: absolute;
    top: 2rem;
    right: 0;
    box-shadow:
        0px 10.5px 36px 0px rgba(0, 0, 0, 0.19),
        0px 3px 13px 0px rgba(0, 0, 0, 0.04);

    // Pops the modal above the content cards when displaying.
    z-index: 2;
}

.invisible {
  display: none;
}

.menuSection, .menuSectionMyHw {
    /* TODO: Determine what margin-block-* is supposed to be doing */
    margin-block-start: 0;
    margin-block-end: 0;
    list-style: none;
}
.menuSection{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    margin: var(--spacing-s) 0;
}
.menuSectionMyHw{
    padding: 15px var(--spacing-m);
}
.menuItem {
    cursor: pointer;
    padding: var(--spacing-s) var(--spacing-m);
    
    &:hover {
        background-color: var(--neutral-x-light);
    }

    &:focus {
        outline: 2px dotted;
        background-color: var(--neutral-x-light);
    }
}

.myHealthwiseSub{
    background-color: var(--neutral-x-light);
    padding: 0.625em 0;
    border-radius: 0.625em;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;

    &:focus {
        outline: 2px dotted;
    }

    & .menuItem {
        display: flex;
        gap: 8px;
        justify-content: center;
        padding: 0 var(--spacing-m);
    }

}

.usermenu {
    /* TODO: This should probably be matched with the font of the username? */
    color: var(--white);
    margin-left: 2em;
    display: flex;
    align-items: center;
}

// This centers the icon nicely in a 24x24 box
.imageWrapper {
    width: 24px;
    height: 24px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

// Constrain the down arrow so the SVG renders properly
.arrowDownIcon {
    width: 15px;
    height: 10px;
    cursor: pointer;
}

.header {
    margin-block-start: 0;
    margin-block-end: 0;

    padding-inline-start: 0;
}

.name {
    font-weight: bold;
    color: #424242;
    margin: 0;
    font-size: var(--font-size-m);
}

.email {
    color: var(--neutral);
    font-size: var(--font-size-s);
    line-height: 20px;
    word-wrap: break-word;
    margin: 0;
}

/* Menu Item */
.label {
    color: #424242;
    vertical-align: middle;
    font-size: var(--font-size);
    line-height: 1.5rem;
}

/* Menu Item */
.icon {
    height: var(--spacing-m);
    width: var(--spacing-m);
    margin-right: 19px;
    vertical-align: middle;
}

/* Menu Item */
.link {
    text-decoration: none;
}
