// Centers the overall footer
.footerPositioning {
    width: 100vw;
    position: absolute;
    bottom: 0;
}

// Setup the container of all the footer elements
.footerContainer {
    // Make sure the image goes under the header and other components.
    z-index: -1;

    background: var(--neutral-color-dark);
}

.footer {
    background: var(--neutral-color-dark);
    color: var(--header-text-color);
    font-size: var(--font-size-xs);
    font-weight: normal;
    text-decoration: none;
    white-space: pre-wrap;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

    // TODO: Need to tie the font size w/ the padding/height
    padding: 0.75rem 1.5rem;
    height: 3rem;

    // Center the element in the active area
    margin: auto;
    max-width: var(--max-page-width);
}

// TODO: Need to adjust the margins as expected (and with variables)
.backgroundContentFooter {
    height: 200px;
    position: relative;
    background-image: url('/assets/images/content-footer.svg');
    background-repeat: no-repeat;
    background-position: bottom right;

    // Make sure the image goes under the header and other components.
    //z-index: -1;

    // Center the element in the active area
    margin: auto;
    max-width: var(--max-page-width);
}
.bulletContainer {
    margin: 0 8px;
}
.barContainer {
    display: inline;
    margin: 0 10px;
}
.footerLink {
    color: var(--header-text-color);
}