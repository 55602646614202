/*!****
(c) 2022-2024  Ignite Healthwise, LLC 

Reference: 
https://zeroheight.com/2edb98123/p/309e70-colors/b/0735ae
*****/
:root {
    /* Leverage the --*-color variable instead to allow for better templating */
    /* Main Colors */
    --primary: rgba(13, 132, 132, 1);
    --primary-dark: rgba(0, 98, 114, 1);
    --primary-light: rgba(170, 226, 208, 1);
    --primary-light-50: rgba(170, 226, 208, 0.5);
    --primary-dark-90: rgba(0, 98, 114, 0.9);
    --stormy: rgba(0, 58, 71, 1);
    --accent: rgba(221, 55, 47, 1);
    --accent-10: rgba(221, 55, 47, 0.1);
    --accent-30: rgba(221, 55, 47, 0.3);
    --accent-dark: rgba(160, 49, 35, 1);
    --skylight: rgba(0, 162, 199, 1);
    --skylight-30: rgba(0, 162, 199, 0.3);

    /* Neutral Colors */
    --white: rgba(255, 255, 255, 1);
    --white-80: rgba(255, 255, 255, 0.8);
    --neutral-xx-light: rgba(248, 248, 248, 1);
    --neutral-x-light: rgba(244, 244, 244, 1);
    --neutral-light: rgba(216, 216, 216, 1);
    --neutral-light-50: rgba(244, 244, 244, 0.5);
    --neutral: rgba(114, 114, 114, 1);
    --neutral-med: rgba(103, 103, 103, 1);
    --neutral-dark: rgba(70, 70, 70, 1);
    --dark: rgba(38, 38, 38, 1);
    --dark-50: rgba(38, 38, 38, 0.5);
    --dark-65: rgba(38, 38, 38, 0.65);
    --control-x-light: rgba(0, 0, 0, 0.04);
    --control-light: rgba(0, 0, 0, 0.08);
    --control-medium: rgba(25, 25, 25, 0.32);

    --black: rgba(0, 0, 0, 1);
    --black-02: rgba(0, 0, 0, 0.02);
    --black-05: rgba(0, 0, 0, 0.05);
    --black-08: rgba(0, 0, 0, 0.08);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-25: rgba(0, 0, 0, 0.25);
    --black-40: rgba(0, 0, 0, 0.4);

    /* Accent Colors */
    --accent--green: rgba(0, 133, 106, 1);
    --accent--green-light: rgba(204, 238, 231, 1);
    --accent--purple: rgba(108, 49, 117, 1);
    --accent--purple-light: rgba(238, 225, 241, 1);
    --accent--yellow: rgba(255, 183, 27, 1);
    --accent--yellow-light: rgba(255, 241, 209, 1);
    --accent--yellow-dark: rgba(117, 80, 0, 1);
    --accent--red: rgba(221, 55, 47, 1);
    --accent--red-light: rgba(248, 215, 213, 1);
    --accent--orange: rgba(255, 108, 55, 1);
    --accent--orange-light: rgba(255, 225, 214, 1);

    /* Accent Blue is defined, but do not use */
    --accent--blue: rgba(0, 166, 206, 1);
    --accent--blue-light: rgba(204, 237, 245, 1);

    --success-color: #cceee7;
    --warning-color: #fff1d1;
    --info-color: #d6eff4;
    --error-color: #f8d7d5;
    --toast-success-color: rgba(13, 132, 132, 1);
    --toast-failure-color: rgba(221, 55, 47, 1);

    /* Page Defaults */
    --max-page-width: 1440px;
    --min-page-width: 300px;

    /* Native Defaults */

    --font-size-xs: 12px;
    --font-size-s: 14px;
    --font-size: 16px;
    --font-size-m: 18px;
    --font-size-l: 22px;
    --font-size-xl: 26px;
    --font-size-xxl: 36px;

    --line-height-s: 17.5px;
    --line-height: 24px;
    --line-height-d: 100%;

    --font-weight: 400;
    --font-weight-strong: 500;
    --font-weight-bold: 700;

    /* Spacing */

    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-s: 12px;
    --spacing: 16px;
    --spacing-m: 24px;
    --spacing-ml: 36px;
    --spacing-l: 48px;
    --spacing-xl: 96px;
    --spacing-xxl: 128px;

    /* Default theme */
    --background-color: var(--white);

    --text-color: var(--neutral-dark);
    --inverse-text-color: var(--white);
    --link-text-color: var(--primary);

    --border-color: var(--neutral);
    --border-color-light: var(--neutral-light);

    --border-radius-rounded: 4px;
    --border-radius-x-rounded: 8px;
    --border-radius-xx-rounded: 16px;

    /* Component Colors */
    --primary-color-light: var(--primary-light);
    --primary-color: var(--primary);
    --primary-color-dark: var(--primary-dark);
    --accent-color: var(--accent);
    --accent-color-dark: var(--accent-dark);
    --neutral-color: var(--neutral);
    --neutral-color-dark: var(--neutral-dark);
    --alert-color: var(--accent--yellow);
    --alert-color-dark: var(--accent--yellow-dark);

    --accent-border-color: var(--accent-dark);
    --disabled-border-color: var(--neutral-color-dark);

    --accent-bg-color: var(--accent-color);
    --accent-bg-color-dark: var(--accent-color-dark);
    --inactive-bg-color: var(--neutral-light-50);
    --disabled-bg-color: var(--neutral-color);
    --neutral-bg-color: var(--neutral-color);
    --neutral-light-bg-color: var(--neutral-light);
    --neutral-border-color: var(--neutral-color-dark);
    --alert-bg-color: var(--alert-color);
    --alert-border-color: var(--alert-color-dark);
    --alert-bg-color-dark: var(--alert-color-dark);
    --success-bg-color: var(--success-color);
    --success-dark-bg-color: var(--accent--green);
    --warning-bg-color: var(--warning-color);
    --info-bg-color: var(--info-color);
    --error-bg-color: var(--error-color);

    --input-border-color: var(--neutral-color);
    --input-bg-color: var(--neutral-light);
    --input-bg-color-outlined: var(--white);
    --input-bg-color-error: var(--accent-10);

    --box-shadow-default: var(--black-10);
    --box-shadow-focus: var(--black-40);

    /* Outline */
    --outline-color: var(--dark);
    --inverse-outline-color: var(--white);

    --outline-width: 2px;
    --outline-style: dotted;
    --outline-offset: 2px;

    --outline: var(--outline-color) var(--outline-style) var(--outline-width);

    --inverse-outline: var(--inverse-outline-color) var(--outline-style) var(--outline-width);

    /* Animation */
    --animation-time: 0.3s ease-out;

    /* Shadows */
    --card-drop-shadow: 0px 3px 6px 0px var(--neutral-light);
    --card-arrow-top-shadow-filter: drop-shadow(0 -3px 2px var(--neutral-light));
    --card-arrow-bottom-shadow-filter: drop-shadow(0 6px 3px var(--neutral-light));
    --snack-drop-shadow: 0px 4px 4px 0px var(--black-25);
    --dialog-drop-shadow: 0px 30px 30px 0px var(--black-20);

    /* A11y high contrast colors */
    --color-forced-light: var(--white);
    --color-forced-dark: var(--black);

    /* Component Defaults */
    --header-bg-color: var(--primary-dark);
    --header-text-color: var(--white);

    --footer-bg-color: var(--neutral-dark);
    --footer-text-color: var(--white);
    --footer-font-size: var(--font-size-xs);

    --input-bg-color: rgba(244, 244, 244, 255);

    /* Content */
    --content-title-font-size: var(--font-size-xxl);
    --content-title-font-weight: var(--font-weight-bold);
    --content-title-line-height: var(--line-height-d);

    --content-h1-font-size: 29px;
    --content-h1-font-weight: var(--font-weight-bold);
    --content-h1-line-height: var(--line-height-d);

    --card-title-font-size: var(--font-size-l);
    --card-title-font-weight: var(--font-weight);
    --card-title-line-height: var(--line-height-d);

    --content-font-size: var(--font-size-l);
    --content-font-weight: var(--font-weight);
    --content-line-height: 28px;

    --content-link-font-color: var(--primary);
    --content-link-text-decoration: underline;
}

html,
body {
    direction: ltr;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family:
        'Roboto',
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        sans-serif;
    color: var(--text-color);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    background-color: var(--background-color, white);
    width: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1 {
    font-weight: var(--font-weight);
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-d);
}

h2 {
    font-weight: var(--font-weight);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-d);
}

h3 {
    font-weight: var(--font-weight);
    font-size: var(--font-size-l);
    line-height: var(--line-height-d);
}

em,
strong {
    font-weight: 700;
}

a {
    color: var(--content-link-font-color);
    text-decoration: var(--content-link-text-decoration);
}

a:focus {
    outline: var(--outline);
    outline-offset: var(--outline-offset);
}

.offscreen-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    background-color: white;
    color: black;
}

.print-only {
    display: none;
}

@media print {
    .no-print {
        display: none !important;
    }
    .print-only {
        display: block !important;
    }
}

/* Couldn't figure out how to get this working in a separate branding.css file... */
label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}
